@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap");

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

.cursive_font {
  font-family: "Gloria Hallelujah", cursive;
}

.whatsappStickbody {
  position: fixed;
  right: 5%;
  bottom: 50px;
  background: green;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #fff;
  font-size: 35px;
  z-index: 2000000000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.whatsappStickActive {
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  background: red;
  border-radius: 50%;
}
.homepage-carouselGene {
  margin-top: 10px;
  margin-bottom: 20px;
}

.cloud_gen img {
  position: absolute;
  bottom: 0;
  z-index: 9999999999;
  width: 100%;
}

.homepage-slider {
  font-family: "Gloria Hallelujah", cursive;
  position: relative;
  width: 100%;
  /* height: 500px; */
}
.homepage-sliderbac {
  width: 100%;
  height: 100%;
}
.homepage-sliderbac img {
  width: 100%;
  height: 100%;
}

.homepage-sliderabso {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
}
.homepage-sliderabso1 h1 {
  text-align: center;
  font-size: 65px;
  /* font-weight: 900px; */
  font-weight: bolder;

  color: #fff;
  line-height: 70px;
  text-shadow: -4px 3px 0 #3a50d9, -14px 7px 0 #0a0e27;
}
.caro_btn {
  color: #fff;

  background: #004200;
  outline: none;
  border: none;
  width: 40px;
  height: 40px;
  font-size: 24px;
  transition: 0.3s ease-in-out 0s;
}

.caro_btn:hover {
  background: green;

  cursor: pointer;
}
.homepage-sliderabso2 h3 {
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  margin-top: 10px;
  line-height: normal;
  font-style: italic;
}

.homepage-sliderabsobtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;

  /* max-width: 200px;
  min-height: 40px; */
  background: #000;
  /* border-radius: 50px; */
  color: #fff;
  font-size: 17px;
  /* font-weight: 900px; */
  font-weight: bolder;
  /* transition: 0.5; */
}

.homepage-sliderabsobtn:hover {
  background: #fff;
  color: #000;

  /* transition: transform 0.2s; */
  transform: scale(1.5);
  transition: 0.5s;
}
/* .homepage-sliderabsobtn {
  display: flex;
  align-items: center;
  justify-content: center;
 
} */

.wavyii {
  text-decoration: underline;
  -webkit-text-decoration-color: #fff;
  text-decoration-color: #fff;
  -webkit-text-decoration-style: wavy;
  text-decoration-style: wavy;
}

.homepage-introFam {
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  margin-top: 100px;
}

.single_flexMission {
  width: 30%;
}

.homepage-introFamHead {
  /* font-family: "Gloria Hallelujah", cursive; */
  font-size: 20px;
  font-weight: 600;
  color: #004200;
  /* border: 1px solid #000; */
  background-color: #bee9be;
  border-right: 2px solid #004200;
  border-left: 2px solid #004200;
  /* padding: 10px 10px; */
  padding-left: 20px;
  height: 50px;

  display: flex;
  align-items: center;
  /* border-radius: 50px; */
  /* justify-content: center; */
}

.homepage-introFamBody {
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  color: #004200;
}

.homepage-introFamBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 2px 30px; */
  width: 200px;
  height: 50px;
  background: #f7acb0;
  border-radius: 50px;
  color: #000000;
  font-size: 15px;
  font-weight: 500px;
  /* width: 50%; */
  margin-bottom: 50px;
}

.homepage-introFamBtn:hover {
  color: #fff;
  background: #000;
  transition: 0.5s;
}

@media screen and (max-width: 992px) {
  .homepage-sliderabso1 h1 {
    text-align: center;
    font-size: 30px;
    font-weight: 900px;
    color: #fff;
    line-height: 40px;
    /* margin-bottom: 20px; */
  }

  .homepage-sliderabso2 h3 {
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-size: 14px;
    font-style: italic;
    margin-top: -10px;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    /* margin-top: 20px; */
  }

  .homepage-sliderabsobtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 30px;

    /* max-width: 200px;
  min-height: 40px; */
    background: #ff1928;
    border-radius: 50px;
    color: #fff;
    font-size: 17px;
    /* font-weight: 900px; */
    font-weight: bolder;
    margin-top: 40px;
    /* transition: 0.5; */
  }

  .homepage-sliderabsobtn:hover {
    background: #fff;
    color: #000;

    /* transition: transform 0.2s; */
    transform: scale(1.1);
    transition: 0.5s;
  }

  .homepage-introFamHead {
    /* font-family: "Gloria Hallelujah", cursive; */
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: #004200;
    /* border-bottom: 2px solid #004200; */
    border-right: 2px solid #004200;
    border-left: 2px solid #004200;

    /* border: 1px solid #000; */
    background-color: #bee9be; /* padding: 10px 10px; */
    padding-left: 5px;
    padding-right: 5px;
    height: 50px;

    display: flex;
    align-items: center;
    text-align: center;
    /* border-radius: 50px; */
    /* justify-content: center; */
  }

  .homepage-introFamBody {
    font-size: 13px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #004200;
  }
}

@media screen and (max-width: 700px) {
  .homepage-introFam {
    display: block;
  }
  .single_flexMission {
    width: 100%;
  }
}

@media screen and (max-width: 630px) {
  .homepage-sliderabso2 h3 {
    font-size: 10px;
    font-style: italic;
    margin-top: -10px;
  }

  .homepage-sliderabso1 h1 {
    font-size: 20px;

    line-height: 40px;
  }
  .caro_btn {
    width: 20px;
    height: 20px;
    font-size: 14px;
  }
}
