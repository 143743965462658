@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}
.ieltsPage-bodyGen {
  margin-right: 50px;
  margin-left: 50px;
}

.ieltsPage-heading-text {
  font-size: 20px;
  font-weight: 600;
  color: #000;

  border-bottom: 2px solid #000;

  /* border: 1px solid #000; */
  /* background-color: #000; */
  /* padding: 10px 10px; */
  padding-left: 20px;
  height: 70px;

  margin-top: 50px;

  display: flex;
  align-items: center;
  border-radius: 50px;

  /* justify-content: center; */
}

.ieltsPage-textComp {
  margin-top: 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 28.8px;
  color: #000;
}

@media screen and (max-width: 992px) {
  .ieltsPage-heading-text {
    font-size: 13px;
    border-bottom: 2px solid #000;

    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
    text-align: center;

    /* justify-content: center; */
  }

  .ieltsPage-textComp {
    margin-top: 30px;
    font-size: 13px;
    font-weight: 400;
    line-height: 28.8px;
    color: #000;
  }
}
