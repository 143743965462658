@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

.maxWidthStyle {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}

.navbar-infoFamFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d8d6d6;
  height: 50px;
  width: 100%;
  background: #ffffff;
  margin-bottom: 10px;
}
.navbar-infoNumber {
  margin-left: 100px;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}

.navbar-infoIconsFam {
  display: flex;
  align-items: center;
  margin-right: 100px;
}
.navbar-infoIconEach {
  margin-right: 15px;
  font-size: 20px;
  color: #000;
}

.navbar-infoIconEach:hover {
  color: #bee9be;
  cursor: pointer;
}

.navbar-optMenuFam {
  display: flex;
  padding-left: 100px;
  padding-right: 100px;
  align-items: center;
  justify-content: space-evenly;
  /* width: 100%; */
  height: 100px;
  background: #ffffff;
}

.mobilenavbar-optMenuFam {
  display: flex;
  /* padding-left: 100px; */
  /* padding-right: 100px; */
  align-items: center;
  justify-content: space-between;
  /* width: 100%; */
  height: 100px;
  background: #ffffff;
}

.mobilenavbar-optMenulogo img {
  width: 80px;
  height: 60px;
  margin-left: 20px;
  margin-top: -25px;
}
.navbar-optMenulogo {
  width: 130px;
  height: 90px;
  margin-right: 20px;
}

.navbar-optMenulogo img {
  width: 130px;
  height: 100px;
}

.navbar-optMenuopt {
  /* margin-right: 15px; */
  color: #004200;
  font-size: 14px;
  font-weight: 600;
  box-sizing: border-box;
  padding: 8px 20px;
}

.navbar-optMenuopt:hover {
  background: #004200;
  color: #bee9be;
  /* border: 1px solid #f7acb0; */
  /* width: ; */
  padding: 8px 20px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
  transition: 0.5s;
}
.activeHeader {
  background: #004200;
  color: #bee9be;
  /* border: 1px solid #f7acb0; */
  /* width: ; */
  padding: 8px 20px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
  transition: 0.5s;
}

.navbar-ExamMenuFam {
  border-top: 1px solid #000000;
  width: 150px;
  /* padding-left: 10px; */

  background: #f8f6f6;
  z-index: 10000;
}

.navbar-ExamMenuOpt {
  display: flex;
  align-items: center;
  /* padding-left: 10px; */
  cursor: pointer;
  height: 30px;
}
.navbar-ExamMenuOpt p {
  /* width: 100%; */

  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
}
.navbar-ExamMenuOpt:hover {
  /* overflow-x: hidden; */
  background-color: #bee9be;
  transition: 0.5s;

  color: #004200;
  width: 150px;
}
.navbar-optMenuoptExamm {
  position: relative;
  display: flex;
  align-items: center;
}

.navbar-ExamMenuFam {
  position: absolute;
}

.navbar-optMenuoptExamArr {
  margin-left: 5px;
}

/* .navbar-optMenuoptExamm.navbar-optMenuoptExamArr :hover {
  translate: -2px;
} */

@media screen and (max-width: 992px) {
  .navbar-infoNumber {
    margin-left: 10px;
  }

  .navbar-infoIconsFam {
    margin-right: 0px;
  }
}
