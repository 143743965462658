@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap");

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

.team_genFlex {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
  margin-bottom: 100px;
}

.teamProcess-FamGen {
  margin-left: 50px;
  margin-right: 50px;
  background: #fff;
}

.team_genOne {
  width: fit-content;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.team_genImg {
  margin-right: 20px;
}

.team_genImg img {
  width: 300px;
  height: 350px;
  /* max-width: 350px; */
  border-radius: 50%;
  /* height: 400px; */
}

.team_directHead {
  font-size: 16px;
  font-weight: 600;
  color: #004200;
  /* background: #bee9be; */
}

.team_directPara {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  font-style: italic;
}

.team_genTwo {
  /* font-family: "Gloria Hallelujah", cursive; */
  width: 60%;
  font-size: 18px;
  font-weight: 500;
  color: #004200;
  line-height: 40px;
  text-align: left;
  font-style: italic;
  border-left: 4px solid #004200;
  padding-left: 10px;
  margin-left: 10px;
}

/* @media screen and (max-width: 700px) {

  
} */

@media screen and (max-width: 700px) {
  .team_genFlex {
    /* display: block; */
    flex-direction: column-reverse;
  }
  .team_genOne {
    width: 100%;
  }
  .team_genImg {
    margin-right: 0px;
  }

  .team_genImg img {
    /* width: ; */
    /* height: none; */
  }

  .team_genTwo {
    width: 100%;
    font-size: 13px;
    margin-bottom: 50px;
    line-height: 25px;
  }
}
