@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap");

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

.admissionProcess-FamGen {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 100px;
  background: #fff;
}

.admissionProcess-FamHead h2 {
  /* font-family: "Gloria Hallelujah", cursive; */
  font-size: 25px;
  font-weight: 900px;
  font-style: normal;

  color: #004200;
  background-color: #bee9be;
  border-right: 2px solid #004200;
  border-left: 2px solid #004200;
  padding: 20px 20px;
  /* border-radius: 50px; */
}

.admissionProcess-Famcont {
  width: 100%;
  word-wrap: break-word;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 100px;
  margin-top: 50px;
}

.about_childOne {
  width: 60%;
}

.about_childTwo {
  width: 37%;
}

.about_childTwo img {
  width: 100%;
  /* width: 300px; */
  height: 300px;
}

.about_headOne {
  font-size: 18px;
  font-weight: bold;
  color: #004200;
  font-style: normal;
  margin-bottom: 20px;
}

.about_bodyOne {
  color: #999;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 50px;
}
.admissionProcess-Famcont p {
  font-size: 18px;
  font-weight: 700px;
  font-style: normal;
  line-height: 30px;
  /* text-align: justify; */

  /* letter-spacing: 0.01em; */
  color: #000;
  text-align: justify;
}

.admissionProcess-FamBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 2px 30px; */
  width: 200px;
  height: 50px;
  background: #004200;
  border-radius: 50px;
  color: #bee9be;
  font-size: 17px;
  font-weight: 500px;
  /* width: 50%; */
  margin-bottom: 50px;
}

.admissionProcess-FamBtn:hover {
  background: #bee9be;
  color: #004200;
  transition: 0.5s;
}

@media screen and (max-width: 992px) {
  .admissionProcess-FamHead h2 {
    /* font-family: "Gloria Hallelujah", cursive; */
    font-size: 14px;
    font-weight: 900px;
    font-style: normal;
    text-transform: uppercase;
    text-align: center;
    color: #004200;
    background-color: #bee9be;
    border-right: 2px solid #004200;
    border-left: 2px solid #004200;

    /* border: 1px solid #000; */
    /* background-color: #000; */
    padding: 20px 20px;
    /* border-radius: 50px; */
  }

  .admissionProcess-Famcont p {
    font-size: 13px;
    font-weight: 700px;
  }
}

@media screen and (max-width: 700px) {
  .admissionProcess-Famcont {
    display: block;
  }

  .about_childOne {
    width: 100%;
  }

  .about_childTwo {
    width: 100%;
  }
}
