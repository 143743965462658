@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

.topFooter-fam {
  width: 100%;
  height: 300px;
  position: relative;
}

.topFooter-famImg {
  width: 100%;
  height: 100%;
}
.topFooter-famImg img {
  width: 100%;
  height: 100%;
}

.topFooter-famText {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(245, 245, 245, 0.01);
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
}

.topFooter-famtext22 {
  font-family: "Gloria Hallelujah", cursive;

  color: #ffffff;
  font-size: 45px;
  font-weight: 600;
}

.topFooter-famteBtn {
  font-size: 16px;
  font-weight: 600;
  color: #bee9be;
  background: #004200;
  padding: 15px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s;
  margin-top: 30px;
  border-radius: 50px;
}

.topFooter-famteBtn:hover {
  transform: scale(1.1);
  background: #bee9be;
  color: #004200;
  transition: 0.5s;
}

.footer-contbor {
  border-left: 3px solid #bee9be;
  margin-right: 20px;
  height: 16px;
}

.footer-contborfam {
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}

.footer-contborfam1 {
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}

.footer-contborfam:hover {
  transform: translate(0, 5px);
  transition: ease-in-out;
}

.footer-contbortext {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}

.footer-Addresborfam {
  display: flex;
  align-items: center;
  /* width: 30%; */
  margin-top: 20px;
}

.footer-general-flexx {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  align-items: flex-start;
  justify-content: space-evenly;
  background: #262626;
}

.footer-AddressIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #bee9be;
  color: #004200;
  font-size: 20px;
  margin-right: 15px;
}

.footer-AddressTextt1 {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}

.footer-AddressTextt2 {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  word-wrap: break-word;
  width: 80%;
}

.footer-PhoneTextt2 {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.footer-EmailTextt2 {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.footer-general-flexx {
  padding-top: 50px;
  padding-bottom: 50px;
}
.footer-fffff {
  width: 30%;
}

.footer-contboruu {
  color: #ffffff;
  font-size: 15px;
  margin-right: 10px;
}

.navbar-infoIconsFam {
  display: flex;
  align-items: center;
  margin-right: 100px;
}
.navbar-infoIconEach {
  margin-right: 15px;
  font-size: 20px;
  color: #000;
}

.navbar-infoIconEach:hover {
  color: #bee9be;
  cursor: pointer;
}

.footer-newsFamGen {
  margin-left: 30px;
  width: 30%;
}

.footer-newsinptext {
  width: 70%;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  word-wrap: break-word;
  margin-top: 20px;
}
.footer-newsinpmainrr {
  margin-top: 10px;
}
.footer-newsinpmain input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid grey;
  font-weight: 400;
  width: 60%;
  background: #f1f1f1;
  color: grey;
  outline: none;
  /* border-radius: 50px 50px 0 0; */
  text-align: center;
  /* display: flex;
  align-items: center; */
}

.footer-newsinpmainbtn {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #004200;
  font-weight: 700;
  /* width: 10%; */
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #004200;
  color: #fff;
  margin-bottom: 10px;
  /* border-radius: 0 0 50px 50px; */
}

.footer-newsinpmainbtn:hover {
  background: #000;
  color: #fff;
  border: 1px solid #000;
  cursor: pointer;
}

.footer-copyrrr {
  font-size: 14px;
  font-weight: 400;
  height: 50px;
  width: 100%;
  background: #000;
  color: #a0a8b1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .footer-general-flexx {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
  }

  .footer-fffff {
    width: 100%;
    /* text-align: center; */
    /* display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; */
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 20px; */
  }

  .footer-contborfamOnne {
    width: 100%;
    margin-top: 40px;
  }

  .footer-newsFamGen {
    margin-top: 40px;
    width: 100%;

    margin-left: 10px;
  }
  .topFooter-famtext22 {
    font-family: "Gloria Hallelujah", cursive;

    color: #ffffff;
    font-size: 20px;
    text-align: center;
  }
  .footer-copyrrr {
    font-size: 11px;
  }
}
