.services_cardGen {
  width: 100%;
  margin: 6px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  margin-top: 20px;

  background: #fff;
  /* padding: 10px; */
  border-radius: 12px;
  box-sizing: border-box;
  transition: 0.3s ease-in-out 0s;
  overflow: hidden;
}

.services_cardImg img {
  object-fit: cover;
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  /* padding: -10px; */
}

.services_cardGen:hover .services_cardImg {
  transform: scale(1.1);
  transition: 0.5s ease all;
  /* transition: 0.3s ease-in-out 0s; */
  box-sizing: border-box;
}
.services_genHead {
  color: #004200;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  margin-top: 15px;
  padding-left: 25px;
  padding-right: 25px;
}

.services_genText {
  color: #999;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  margin-top: 10px;
  line-height: 22px;
  /* margin-bottom: 20px; */
  padding-left: 25px;
  padding-right: 25px;
}

.services_addressText {
  color: #555;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  margin-top: 5px;
  line-height: 22px;
  margin-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
}
