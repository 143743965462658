@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap");

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

.examProfContFamGenn {
  padding-left: 50px;
  padding-right: 50px;
  /* background: #d5d3d3; */
  background: #e1fde1;
  padding-top: 20px;
  position: relative;
  padding-bottom: 50px;
}

.examProfContHead h2 {
  /* font-family: "Poppins"; */
  /* font-family: "Gloria Hallelujah", cursive; */
  font-size: 25px;
  font-weight: 900px;
  font-style: normal;

  color: #004200;
  /* border: 1px solid #000; */
  background-color: #bee9be;
  border-right: 2px solid #004200;
  border-left: 2px solid #004200;

  padding: 20px 20px;
  /* border-radius: 50px; */
  margin-top: 150px;
}
.cloud_down img {
  position: absolute;
  top: 0;
  z-index: 9999999999;
  width: 100%;
  left: 0;
}
.examProfContEach-name h4 {
  font-size: 24px;
  font-weight: 900px;
  font-style: normal;

  color: #000;
}

.examProfContEach-info {
  width: 90%;
  word-wrap: break-word;
}

.examProfContEach {
  width: 30%;
  /* background: #f3f3f3; */
}

.examProfContEach-info p {
  font-size: 18px;
  font-weight: 700px;
  font-style: normal;
  line-height: 30px;
  text-align: justify;
  /* letter-spacing: 0.01em; */
  color: #000;
}

.examProfContEach-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 2px 30px; */
  width: 200px;
  height: 50px;
  background: hsl(357, 82%, 82%);
  border-radius: 50px;
  color: #000000;
  font-size: 15px;
  font-weight: 500px;
  /* width: 50%; */
  margin-bottom: 50px;
}

.examProfContEach-btn:hover {
  color: #fff;
  background: #000;
  transition: 0.5s;
}

.examProfContFamm {
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  justify-content: space-between;
  /* margin-bottom: 50px; */
}

@media screen and (max-width: 1000px) {
  .examProfContEach {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
  }

  .examProfContHead h2 {
    /* font-family: "Poppins"; */
    /* font-family: "Gloria Hallelujah", cursive; */
    font-size: 14px;
    font-weight: 900px;
    font-style: normal;

    color: #004200;
    /* border: 1px solid #000; */
    background-color: #bee9be;
    border-right: 2px solid #004200;
    border-left: 2px solid #004200;
    padding: 20px 20px;
    /* border-radius: 50px; */
    text-transform: uppercase;
  }

  .examProfContEach-info p {
    font-size: 13px;
    font-weight: 700px;
    font-style: normal;
    line-height: 30px;
    text-align: justify;
    /* letter-spacing: 0.01em; */
    color: #000;
  }
}

@media screen and (max-width: 700px) {
  .examProfContEach {
    width: 100%;
  }

  .examProfContFamm {
    display: block;
  }
}
