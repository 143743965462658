@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap");

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
}

.regisPage-genFam {
  margin-right: 50px;
  margin-left: 50px;
}

.ourgallery-heading-text {
  /* font-family: "Gloria Hallelujah", cursive; */

  font-size: 20px;
  font-weight: 600;
  color: #004200;
  background-color: #bee9be;
  border-right: 2px solid #004200;
  border-left: 2px solid #004200;
  /* padding: 10px 10px; */
  padding-left: 20px;
  height: 70px;

  margin-top: 50px;

  display: flex;
  align-items: center;
  /* border-radius: 50px; */

  /* justify-content: center; */
}
.regisPage-genFam-inputField {
  position: relative;
  max-width: 800px;
  /* margin-left: 10px;
    margin-right: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.regisPage-genFam-inputFields {
  position: relative;
  max-width: 857px;
  /* margin-left: 10px;
    margin-right: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}
.regisPage-genFam-inputFields select {
  padding: 8px 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 50px;
  border: none;
  border-bottom: 1px solid #cbd5e0;
  /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
  /* border-radius: 3px; */
  width: 100%;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #333333;
  -webkit-appearance: none;
  border-radius: 0;
  background: #dde2e6;
  outline: none;
}
.inputFieldPersonalf {
  margin-top: 30px;
  max-width: 857px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.inputFieldPersonalf textarea {
  font-family: "Poppins";
  width: 100%;
  -webkit-appearance: none;
  border-radius: 0;
  height: 120px;
  border: none;
  border-bottom: 1px solid #cbd5e0;
  outline: none;
  font-weight: 400;
  font-size: 14px;

  /* Text color */

  color: #333333;
  padding-left: 10px;
  padding-left: 10px;
  /* padding-right: 10px; */
}
.inputFieldPersonal textarea:focus {
  border-bottom: 2px solid #36a6a4;
}
.regisPage-genFam-inputField select:focus {
  /* border: 1.8px solid #6b6b6b; */
  border: none;
  outline: none;
  border-bottom: 2px solid #f7acb0;
}
.regisPage-genFam-inputField input {
  padding: 8px 10px;
  padding-top: 20px;
  padding-right: 50px;
  border: none;
  border-bottom: 1px solid #cbd5e0;
  /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
  /* border-radius: 3px; */
  width: 100%;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #333333;
  -webkit-appearance: none;
  border-radius: 0;
  background: #dde2e6;
}

.regisPage-genFam-inputField input:focus {
  /* border: 1.8px solid #6b6b6b; */
  border: none;
  outline: none;
  border-bottom: 2px solid #f7acb0;
}
.regisPage-genFam-inputField span {
  pointer-events: none;
  opacity: 0.5;
  position: absolute;
  padding-left: 20px;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: text;

  font-weight: 500;
  font-size: 14px;

  /* Text color */

  color: #333333;
}

.regisPage-genFam-inputField input:focus + span,
.regisPage-genFam-inputField input:not(:placeholder-shown) + span {
  top: 7px;
  -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px);
  transform: scale(0.7) translateY(-10%) translateX(-8.5px);
  font-size: 14px;
}
.regisPage-genFam-inputField input,
.regisPage-genFam-inputField span {
  /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.regisPage-genFam-inputFieldSubmit {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.regisPage-genFam-inputFieldSubmit input {
  /* padding: 15px 70px; */
  width: 300px;
  height: 40px;
  border: none;
  outline: none;
  background: #dd3333;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}
.regisPage-genFam-inputFieldSubmit input:hover {
  background: #000;
  transition: 0.5s;
  color: #fff;
}
.regisPage-fillFormHead {
  text-align: center;

  font-size: 20px;
  font-weight: 600;
  color: #fff;
  /* border: 1px solid #000; */
  background-color: #000;
  max-width: 856px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.regisPage-fillFormGuide {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #dd3333;
}

.regisPage-fillFormGui {
  margin-top: 30px;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #dd3333;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.regisPage-fillFormGuid {
  /* margin-top: 30px; */
  margin-bottom: 50px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #dd3333;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 992px) {
  .regisPage-genFam-inputField span {
    font-weight: 500;
    font-size: 10px;
  }

  .regisPage-genFam-inputField input:focus + span,
  .regisPage-genFam-inputField input:not(:placeholder-shown) + span {
    top: 7px;
    -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px);
    transform: scale(0.7) translateY(-10%) translateX(-8.5px);
    font-size: 10px;
  }

  .regisPage-genFam-inputFields select {
    width: 100%;
  }

  .ourgaller-heading-text {
    font-size: 13px;

    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
    text-align: center;

    /* justify-content: center; */
  }
}
