@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap");

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

.aboutUs-heading-text {
  /* font-family: "Gloria Hallelujah", cursive; */
  font-size: 20px;
  font-weight: 600;
  color: #004200;
  /* border: 1px solid #000; */
  /* background-color: #000; */
  /* padding: 10px 10px; */
  /* border-bottom: 2px solid #000; */
  background-color: #bee9be;
  border-right: 2px solid #004200;
  border-left: 2px solid #004200;
  padding-left: 20px;
  height: 70px;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 50px;

  display: flex;
  align-items: center;
  /* border-radius: 50px; */

  /* justify-content: center; */
}

.aboutUs-heading-body {
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  margin-left: 50px;
  margin-right: 50px;
  text-align: left;
  color: #004200;
}

.aboutUs-MissionText {
  display: flex;
  margin-left: 50px;
  margin-right: 50px;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 100px;
}
.aboutUs-MissionEachHead {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  border-bottom: 2px solid #000;

  /* border: 1px solid #000; */
  /* background-color: #000; */
  /* padding: 10px 10px; */
  padding-left: 20px;
  height: 70px;

  margin-top: 50px;

  display: flex;
  align-items: center;
  border-radius: 50px;
  width: 100%;
}
.aboutUs-MissionEach {
  width: 47%;
}

.aboutUs-MissionEachBody {
  width: 100%;
  word-wrap: break-word;
  font-size: 16px;
  text-align: justify;
  font-weight: 400;
  line-height: 35px;
}

@media screen and (max-width: 992px) {
  .aboutUs-heading-text {
    /* font-family: "Gloria Hallelujah", cursive; */
    font-size: 14px;
    font-weight: 600;
    color: #004200;
    /* border: 1px solid #000; */
    /* background-color: #000; */
    /* border-bottom: 2px solid #000; */
    background-color: #bee9be;
    border-right: 2px solid #004200;
    border-left: 2px solid #004200;

    /* padding: 10px 10px; */
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    height: 70px;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-radius: 50px; */

    /* justify-content: center; */
  }

  .aboutUs-MissionText {
    flex-direction: column;
  }
  .aboutUs-MissionEach {
    width: 100%;
  }

  .aboutUs-MissionEachHead {
    font-size: 14px;
    border-bottom: 2px solid #000;

    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    justify-content: center;
  }
  .aboutUs-heading-body {
    font-size: 13px;
  }

  .aboutUs-MissionEachBody {
    font-size: 13px;
  }
}
